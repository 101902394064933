import React from 'react';
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby';
import { Navigation, Footer, CopyrightLabel, SiteMap, SocialMedia, PublishBanner } from '@fishrmn/fishrmn-components';
import { Link } from 'gatsby';
import 'semantic-ui-less/semantic.less';
import './layout.css';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFisherman {
          edges {
            node {
                       businessName 
          socialMedia {
            link 
            type 
          }
          logo 
            }
          }
        }
        allFishermanImages {
          edges {
            node {
              heroImages {
                url
              }
              galleryImages {
                url
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      const businessData = queryData.allFisherman.edges[0].node;
      const imageData = queryData.allFishermanImages.edges[0].node;
      const data = { ...businessData, ...imageData };

      return (
        <div className="site-container">
          <div>
            <Helmet>
              <script>
                {'document.querySelectorAll(\'[label="Order Online"]\')[0].setAttribute("class", "chownow-order-online");\n' +
                'document.querySelectorAll(\'[label="Order Online"]\')[0].removeAttribute("href");\n' +
                'document.getElementsByClassName(\'footer-container-primary\')[0].children[0].children[1].children[0].children[3].children[0].setAttribute("class", "content chownow-order-online")\n' +
                'document.getElementsByClassName(\'footer-container-primary\')[0].children[0].children[1].children[0].children[3].children[0].removeAttribute("href");'}
              </script>
            </Helmet>
            <Navigation
               links={ [{"pageTitle":"Home","slug":"/","internal":true},{"pageTitle":"Contact","slug":"/contact/","internal":true},{"pageTitle":"Menu","slug":"/menu/","internal":true},{"pageTitle":"Order Online","slug":"https://ordering.chownow.com/order/2274/locations","internal":false, className: "chownow-order-online"}] }
               backgroundColor={ "white" }
               bordered={ false }
               centerMobileNavItems={ true }
               evenSpacing={ false }
               fixed={ false }
               header={ data.businessName }
               linksAs={ "h3" }
               logo={ data.logo }
               primaryContentPosition={ "left" }
               primaryContentCentered={ false }
               internalLinkComponent={Link}
            />
          </div>
          <div className="page-container">{children}</div>
          <Footer
             backgroundColor={ "primary" }
             horizontalAlign={ "center" }
             verticalAlign={ "middle" }
             columns={[
               <CopyrightLabel
                  company={ data.businessName }
                  phrase={ "All Rights Reserved" }
               />,
               <SiteMap
                  links={ [{"pageTitle":"Home","slug":"/","internal":true},{"pageTitle":"Contact","slug":"/contact/","internal":true},{"pageTitle":"Menu","slug":"/menu/","internal":true},{"pageTitle":"Order Online","slug":"https://ordering.chownow.com/order/2274/locations","internal":false, className: "chownow-order-online"}] }
                  horizontal={ true }
                  bulleted={ false }
                  internalLinkComponent={ Link }
               />,
               <SocialMedia
                  socialMediaValues={ data.socialMedia }
                  buttonType={ "circle" }
                  centerHeader={ false }
                  colors={ "secondary" }
                  groupButtons={ false }
                  groupVertically={ false }
                  header={ "" }
                  inverted={ false }
                  labelPosition={ "free" }
                  showLabels={ false }
               />,
             ]}
          />
        </div>
      )
    }}
  />
);